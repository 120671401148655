<template>
    <div style="margin:10px;">
        <div style="text-decoration:underline;cursor:pointer">{{item.title}}</div>
        <div style="margin-top:5px">{{item.createTime}}</div>
    </div>
    <div style="height:2px;background-color: #eee;"></div>
    <div style="padding:10px">{{item.content}}</div>
</template>

<script>
    export default {
      data() {
        return {
          id:0,
          item: ''
        }
      },
      mounted: function () {
        this.id = this.$route.params.id
        let page = this
        this.$net.noticeList().then(({ data }) => {
          this.item = data.list.find(it=>it.id==page.id)
        });
      }
    }
  </script>